import { colors } from "@/components/Providers/ThemeRegistry/theme"
import { Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import Box from "@/components/Box"
import Image from "next/image"
import Link from "next/link"
import React, { FC, PropsWithChildren } from "react"

// Scrolling background will render two copies of children so that the user sees
// a loop as the animation reaches the end of the list of content. Once the
// first piece of content has reached the beginning of the container, the
// animation repeats. To account for how CSS keyframes work for repeating
// animations, and avoid any stutter when the animation repeats, the animation
// will push the list slightly further than the actual end (50.8% vs 50%).
const AnimatedFeed: FC<PropsWithChildren> = ({ children }) => (
  <Box sx={{ position: "relative", width: "100%", minHeight: "272px" }}>
    <Stack
      className="ticker"
      direction="row"
      gap="80px"
      flexShrink={1}
      sx={{
        position: "absolute",
        alignItems: "baseline",
        "&:hover": {
          animationPlayState: "paused",
        },
        animation: "ticker-scroll 50s linear infinite",
        "@keyframes ticker-scroll": {
          "0%": {
            transform: "translateX(0%)",
          },
          "25%": {
            transform: "translateX(-12.6%)",
          },
          "50%": {
            transform: "translateX(-25.2%)",
          },
          "75%": {
            transform: "translateX(-37.8%)",
          },
          "100%": {
            transform: "translateX(-50.8%)",
          },
        },
      }}
    >
      {children}
      {children}
    </Stack>
  </Box>
)

const TwitterFeed = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Box
      width="100%"
      maxWidth="xl"
      overflow="hidden"
      sx={{
        maxWidth: { xs: "100%", md: "xl" },
        padding: { xs: "80px 0 0", md: "120px 64px 0" },
      }}
    >
      <Box width="100%" overflow="hidden" gap="80px" position="relative">
        <AnimatedFeed>
          <Tweet
            text="Few projects are marrying the innovation of DeFi with the ubiquity & trust of traditional financial assets via RWA like Ondo and Flux Finance."
            username="MMInstitutional"
            imageUrl="https://pbs.twimg.com/profile_images/1616761899861020673/qxa3gqcu_400x400.jpg"
            hrefUrl="https://x.com/MMInstitutional/status/1770088065165406312"
          />
          <Tweet
            text="Unlocking $ONDO: A Proposal from the Ondo Foundation. The Ondo Foundation is thrilled to present our proposal to release $ONDO tokens from the global lock-up, a major step in aligning our community toward a future of open, transparent, and efficient global finance..."
            username="OndoFoundation"
            imageUrl="https://pbs.twimg.com/profile_images/1737846990778851328/KH0PALNY_400x400.jpg"
            hrefUrl="https://x.com/OndoFoundation/status/1740081220699009382"
          />
          <Tweet
            text="If you're building RWA-focused crypto products, follow @OndoFinance's lead - integrate with blockchains that have action and liquidity. Focus on market demand and PMF, prioritize nimbleness and interoperability. Flow with the money."
            username="g_djuric"
            imageUrl="https://pbs.twimg.com/profile_images/1594356730451955721/IDYXHtjG_400x400.jpg"
            hrefUrl="https://twitter.com/g_djuric/status/1737268581115510787"
          />
          <Tweet
            text="Well there's $135B of tokenized dollars that are already being permissionlessly composed into applications today @FluxDeFi takes an interesting approach to enabling yield on permissioned assets (treasuries) to flow to permissionless assets (USDC)"
            username="ChainLinkGod"
            imageUrl="https://pbs.twimg.com/profile_images/1799188365302018048/adkupUUf_400x400.jpg"
            hrefUrl="https://x.com/ChainLinkGod/status/1619528989365895168"
          />
          <Tweet
            text="The integration of Real World Assets into DeFi is critical for retail and institutional adoption. Perhaps just importantly, it enables on-chain users to gain access to the risk-free rate, earning yields between 4-8%, without leaving the Web3 space. Excellent work by @OndoFinance"
            username="Mega_Fund"
            imageUrl="https://pbs.twimg.com/profile_images/1659579562181656580/E_Ck_FuP_400x400.jpg"
            hrefUrl="https://x.com/Mega_Fund/status/1613266177505255429"
          />
          <Tweet
            text="1/ Will the #RealWorldAssets please stand up? Capitalizing on more appealing Treasury yields, several protocols have emerged that aim to bring off-chain yields onto the blockchain.  @FTI_US' Government Money Market Fund & @OndoFinance's OUSG stablecoin are most notable. 🧵"
            username="MessariCrypto"
            imageUrl="https://pbs.twimg.com/profile_images/1706771641932419072/HA20H-rN_400x400.jpg"
            hrefUrl="https://x.com/MessariCrypto/status/1680976919100268547?s=20"
          />
          <Tweet
            text="Ingenious idea! Compound V2 fork with OUSG (permissioned short dur USTs), DAI and USDC. KYCed entities can leverage with OUSG to provide TradFi yield to DAI/USDC depositors. What you get is a virtually unlimited sink of 2-3% permissionless yield in DeFi. More of this!!"
            username="hexonaut"
            imageUrl="https://pbs.twimg.com/profile_images/1699607742049337344/EVQCcUOn_400x400.jpg"
            hrefUrl="https://x.com/hexonaut/status/1613679447034720256"
          />
        </AnimatedFeed>
        {!isMobile && (
          <>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "80px",
                height: "100%",
                background: "linear-gradient(90deg, #F2F3F6 0%, rgba(242, 243, 246, 0.00) 100%)",
                zIndex: 1,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "80px",
                height: "100%",
                background: "linear-gradient(90deg, rgba(242, 243, 246, 0.00) 0%,  #F2F3F6 100%)",
                zIndex: 1,
              }}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

const Tweet: React.FC<{ text: string; username: string; imageUrl: string; hrefUrl: string }> = ({
  text,
  username,
  imageUrl,
  hrefUrl,
}) => (
  <Button
    component={Link}
    href={hrefUrl}
    target="_blank"
    sx={{
      padding: 0,
      "&:hover": {
        backgroundColor: "unset",
        "& h6, & p": {
          color: colors.base.black,
        },
      },
    }}
  >
    <Stack gap="24px" width="360px" minHeight="244px" flexShrink={0} flexBasis={"360px"} justifyContent="start">
      <Typography variant="h6" sx={{ userSelect: "none", color: colors.base.greyMid2 }}>
        {text}
      </Typography>
      <Stack flexDirection="row" gap="12px">
        <Image
          src={imageUrl}
          width={24}
          height={24}
          alt="profile-picture"
          style={{
            borderRadius: "100%",
          }}
        />
        <Typography variant="body1" sx={{ color: colors.base.greyMid2 }}>
          @{username}{" "}
        </Typography>
      </Stack>
    </Stack>
  </Button>
)

export default TwitterFeed
